/* eslint-disable import/no-extraneous-dependencies */
import i18n from "i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

interface ILanguage {
  [key: string]: string;
}

const ENV_LANGUAGE = String(
  process.env.REACT_APP_LANGUAGE ?? "id"
).toUpperCase();

const LANGUAGE: ILanguage = {
  ID: "id-ID",
  EN: "en-US",
};

void i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    debug: true,
    fallbackLng: "id",
    lng: LANGUAGE[ENV_LANGUAGE as keyof ILanguage],
  });
export default i18n;
