/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import "antd/dist/antd.css";
// eslint-disable-next-line import/extensions
import "styles/global.scss";
import "react-quill/dist/quill.snow.css";

import { Suspense, useEffect, useState } from "react";

import { Spin } from "antd";
import { useAuthContext } from "context/AuthContext";
import { LayoutDefault } from "layouts";
import LoginPage from "pages/Auth/Login";
import VerificationPage from "pages/Auth/Verification";
import ForbiddenPage from "pages/Forbidden";
import OfflinePage from "pages/Offline";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes";
import {
  getPlainSetting,
  getPlainSettingGeneral,
} from "services/setting/general";
import { IRoute } from "types";

function App(): JSX.Element {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthContext();
  const [isDisabledSite, setDisabledSite] = useState(false);

  function RouteWithSubRoutes(route: IRoute) {
    return (
      <>
        <Route exact path={route.path} component={route.component} />
        {route.subMenu?.map((subRoute, index) =>
          subRoute.subMenu ? (
            <RouteWithSubRoutes
              key={`sub-${subRoute.name}-${index}`}
              {...subRoute}
            />
          ) : (
            <Route
              key={`sub-${subRoute.name}-${index}`}
              exact
              path={subRoute.path}
              component={subRoute.component}
            />
          )
        )}
      </>
    );
  }

  useEffect(() => {
    void getPlainSettingGeneral(String(process.env.REACT_APP_API_ENDPOINT));
    void getPlainSetting(String(process.env.REACT_APP_API_ENDPOINT)).then(
      (setting) => {
        setDisabledSite(setting.maintenance?.isDisabled);
      }
    );
  }, []);

  return isDisabledSite ? (
    <OfflinePage />
  ) : (
    <div className="App">
      <Suspense
        fallback={
          <div className="page-loading">
            <Spin size="large" />
          </div>
        }
      >
        <Switch>
          <Route exact path="/signin" component={LoginPage} />
          <Route exact path="/verification" component={VerificationPage} />
          <Route
            exact
            path="/"
            render={() =>
              isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/signin" />
              )
            }
          />
          <LayoutDefault>
            {routes.map((value, index) =>
              isAuthenticated ? (
                <RouteWithSubRoutes
                  key={`menu-${value.name}-${index}`}
                  {...value}
                />
              ) : (
                <Redirect
                  key={`menu-${value.name}-${index}`}
                  to="/signin"
                  from={pathname}
                />
              )
            )}
            <Route exact path="/403" component={ForbiddenPage} />
          </LayoutDefault>
        </Switch>
      </Suspense>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense
      fallback={
        <div className="page-loading">
          <Spin size="large" />
        </div>
      }
    >
      <App />
    </Suspense>
  );
}
