export default {
  LOGIN: "/admin/login",
  OTP: "/otp",
  BANNER: "/banner",
  VOUCHER: "/voucher",
  PROVIDER: "/provider",
  VENDOR_DENOM: "/vendor-item",
  VENDOR_PRODUCT: "/vendor-product",
  BOT: "/bot",
  BOT_TAG: "/bot/tag",
  MEMBERSHIP: "/membership",
  MEMBERSHIP_ITEM: "/membership/item/price",
  CATEGORY: "/category",
  MEDIA: "/media",
  ORDER: "/order",
  TOPUP: "/topup",
  WITHDRAW: "/withdraw",
  PAYMENT: "/payment",
  PAYMENT_CHANNEL: "/payment/channels",
  PAYMENT_CHANNEL_BANK_TRANSFER: "/payment/channels/bank-transfer",
  PAYMENT_CHANNEL_MANUAL_TRANSFER: "/payment/channels/manual",
  LOG: "/log",
  PRODUCT: "/product",
  PRODUCT_VARIANT: "/product/variant",
  PRODUCT_ITEM: "/product/items",
  USER: "/user",
  USER_GROUP: "/user-group",
  SETTING_GENERAL: "/setting/general",
  SETTING_SYSTEM: "/setting/system",
  SETTING_WHATSAPP: "/setting/whatsapp",
  SETTING_EMAIL: "/setting/email",
  SETTING_API_KEY: "/setting/api-key",
  SETTING_CALLBACK: "/setting/callback",
  SETTING_BANNER: "/setting/banner",
  SIMULATION: "/simulation",
  SIMULATION_WHATSAPP: "/simulation/whatsapp",
  REPORT: "report",
  REPORT_SUMMARY: "report/total",
  REPORT_PAYMENT: "report/payment",
  REPORT_PRODUCT: "report/product",
  REPORT_INCOME_PRODUCT: "/report/income/product",
  REPORT_INCOME_TOP_UP: "/report/income/wallet",
  REPORT_INCOME_MEMBERSHIP: "/report/income/membership",
  REPORT_INCOME_SUMMARY: "/report/income/summary",
  REPORT_CASH: "report/income/cash-collected",
  REPORT_SALES_SUMMARY: "/report/product/summary",
  REPORT_CASH_SUMMARY: "report/income/cash-collected/summary",
  REPORT_USER: "/report/user",
  REPORT_VOUCHER: "/report/voucher",
  REPORT_POINT: "/report/user/point",
  MIDMAN: "/midman",
  EMBED: "/embed",
  PAGE: "/page",
  ARTICLE: "/articles",
  REVIEW: "/reviews",
  PERMISSION: "/permissions",
  SYNC: "/synchronize",
};
